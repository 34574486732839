
import React, {useState} from "react"
import Layout from "../../layout"

import WebappForm from "../form"
import WebappWidgetTable from "../widget/table"

const formatTools = require( "../../../../lib/formatTools");
const webappsAPI = require("../../../../lib/requestWebapps");


const ENTITYID="conversation";


const searchFields = [
	{"label":"Latest Activity", "dbfield": "conversation_date", "type": "datetime", "filtertype": "datetime"},
	{"label":"Number", "dbfield": "conversation_refno", "type": "text", "filtertype": "text"},
	//{"label":"Name", "dbfield": "conversationname_name", "type": "text", "filtertype": "text"},
	//{"label":"New Message", "dbfield": "conversation_unread", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Last Received", "dbfield": "conversation_msgin", "type": "custom", "filtertype": "text"},
	{"label":"Last Receive Date", "dbfield": "conversation_datein", "type": "hidden", "filtertype": "datetime"},
	{"label":"Last Sent", "dbfield": "conversation_msgout", "type": "custom", "filtertype": "text"},
	{"label":"Last Sent Date", "dbfield": "conversation_dateout", "type": "hidden", "filtertype": "datetime"},
	//{"label":"Notes", "dbfield": "conversation_notes", "type": "text", "filtertype": "text"},
];


const newformFields = [
	{"label":"Number", "field": "conversation_refno", "value": "", "input": "textbox", "mode": "unique"},
	{"label":"Send Message", "field": "conversation_newmsg", "value": "", "input": "textarea", "mode": "required"},
];

const formFields = [
	{"label":"Number", "field": "conversation_refno", "value": "", "input": "text", "mode": "readonly"},
	{"label":"History", "field": "conversation_notes", "value": "", "input": "text", "mode": "readonly"},
	{"label":"Send Message", "field": "conversation_newmsg", "value": "", "input": "textarea", "mode": "required"},
];


const conversationitemFields = [
	{"label":"Date/Time", "dbfield": "conversationitem_date", "type": "hidden", "filtertype": "datetime"},
	{"label":"Type", "dbfield": "conversationitem_type", "type": "hidden", "filtertype": "hidden"},
	{"label":"Sender", "dbfield": "conversationitem_sender", "type": "hidden", "filtertype": "hidden"},
	{"label":"Message", "dbfield": "conversationitem_msg", "type": "custom", "filtertype": "textbox"},
];


const conversationitemFilters = [
	{"label":"Date/Time", "dbfield": "conversationitem_date", "filtertype": "datetime"},
	{"label":"Message", "dbfield": "conversationitem_msg", "filtertype": "textbox"},
];


const conversationitemMobileTitle = ["conversationitem_date"];


const WebappsConversationpageComponent = ({location, allowadd}) => {
	const [token, setToken] = useState("");
	const [userinfo, setUserinfo] = useState({});
	const [pagetitle, setPagetitle] = useState("");
	const [conversationitemqueryparam, setConversationitemqueryparam] = useState({})
	const [docid, setDocid] = useState(0);
	const [destnumber, setDestnumber] = useState("");

	const todaydate = formatTools.getDateStr().substring(0,10);
	const defaultstartdate = formatTools.getDateStr(8-24*21); // 3 weeks ago

	const conversationitemInitState = {
		"filter":[
			{
				"display": "Past 3 Weeks",
				//"display":"Order date after '"+formatTools.datestr(defaultstartdate)+"'",
				"field":"conversationitem_date", "operation":" >= ","value":defaultstartdate
			},
		],
		"orderbyfield": "conversationitem_date",
		"orderbymode": ""
	};

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		const updatetimestr = formatTools.getDateStr();
		var customparam = JSON.parse(JSON.stringify(params));
		var destnum = params.conversation_refno.replace(/[^0-9+]/g, "");

		delete customparam.conversation_newmsg;
		customparam.conversation_refno = destnum;
		customparam.conversation_dateout = updatetimestr;
		customparam.conversation_date = updatetimestr;
		customparam.conversation_msgout = params.conversation_newmsg;

		//callback({"status":"Error","message":"Here"}); return;
		//destnum
		webappsAPI.savePayload(entity, customparam, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				customparam = {
					conversation_id: response.documentid,
					conversationitem_type: 2, // Outbox
					conversationitem_date: updatetimestr,
					conversationitem_sender: userinfo.name,
					conversationitem_msg: params.conversation_newmsg
				};

				webappsAPI.savePayload("conversationitem", customparam, token, [], [], function(msgresponse) {
					callback(response);
				});
			} else {
				callback(response);
			}
		});
	}

	function formValidateValues(mainform, subformlist, subformfooterlist)
	{
		if (docid === 0) {
			var tmpstr = "";
			var idx = 0;
			while (idx < mainform.length) {
				if (mainform[idx].field === "conversation_refno") {
					tmpstr = mainform[idx].value.replace(/[-\(\)\s]/g, '');
					if (tmpstr.substring(0,1) === "+") {
						tmpstr = tmpstr.substring(1);
					}
					tmpstr = tmpstr.replace(/[0-9]/g, '');
					if (tmpstr.length > 0) {
						return {"status":"Error", "message":"Invalid number "+ mainform[idx].value};
					}
					tmpstr = mainform[idx].value.replace(/[^0-9+]/g, '');
					if (tmpstr.substring(0,1)==="0") {
						return {"status":"Error", "message":"Please use +63NNNMMMMMMM format"};
					}
				}
				idx++;
			}
		}


		return {"status":"OK"};
	}


	function customEditFieldInfo(inputlist, idx)
	{
		if (docid > 0) {
			if (inputlist[idx].field === "conversation_newmsg") {
				if (destnumber.length < 1) {
					var tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
					tmpfield.mode = "readonly";
					tmpfield.info = "Cannot send messages to this number";
					return tmpfield;
				}
			}
		}

		return inputlist[idx];
	}

	function customFieldFragment(inputlist, idx, isdisabled, fieldid, inputchangehandler)
	{
		if (inputlist[idx].field === "conversation_notes") {
			return <WebappWidgetTable
						disabled={isdisabled}
						title={"Messages"}
						token={token}
						entity={"conversationitem"}
						fieldList={conversationitemFields}
						mobilerowtitlefield={conversationitemMobileTitle}
						filterfieldList={conversationitemFilters}
						userparam={conversationitemqueryparam}
						initsearchstate={conversationitemInitState}
						customShowField={customShowField}
						showRownum={false}
						showControls={true}
						showRefresh={false}
					/>
			// Hide field
			//return <></>;
		}
		// default
		return null;
	}

	function customSetFormData(newformdata)
	{
		var tmpdestnum = "";
		var conversation_id = 0;
		if (newformdata.hasOwnProperty("conversation_id")) {
			if (newformdata.conversation_id) {
				conversation_id = newformdata.conversation_id;
			}
		}
		if (newformdata.hasOwnProperty("conversation_refno")) {
			if (newformdata.conversation_refno) {
				tmpdestnum = newformdata.conversation_refno.replace(/[^0-9]/g, '');
				if (tmpdestnum.length > 0) {
					if (tmpdestnum.substring(0,1) === "0") {
						tmpdestnum = "+63"+tmpdestnum.substring(1);
					} else {
						tmpdestnum = "+"+tmpdestnum;
					}

				}
			}
		}

		setDocid(conversation_id);
		setDestnumber(tmpdestnum);
		setConversationitemqueryparam({
			tablelist: [],
			joinlist: [],
			condlist: ["CONVERSATIONITEM.conversation_id="+conversation_id]
		});
	}

	function customShowField(field, idx, rowdata, styles)
	{
		//conversationitem_msg
		if (field.dbfield === "conversationitem_msg") {
			const conversationitemtype = parseInt(rowdata["conversationitem_type"],10);
			const sendername = conversationitemtype>=2?rowdata["conversationitem_sender"]!==null?rowdata["conversationitem_sender"]:"":"";
			const tmpstr = rowdata[field.dbfield];
			const msgstyle = {textAlign:"left", display:"inline-block", width: "80%"};
			const containerstyle = {
				padding: "1vw",
				borderRadius: "1vw",
				backgroundColor: conversationitemtype===1?"#c4dec4":(tmpstr.indexOf("THIS IS A SYSTEM GENERATED MESSAGE")>=0?"#eeeeee":"#c4d9de")
			};
			return <div style={{textAlign: (conversationitemtype===1?"left":"right"), margin:"0.5vw 2vw"}}>
				<div style={msgstyle}>
					<div style={containerstyle} dangerouslySetInnerHTML={{__html: tmpstr.replace(/\n/g,"<br/>")}} />
					<div className={"font-size-tiny"} style={{padding: "1vw"}}>{(conversationitemtype >= 2?(conversationitemtype === 2?" Sending...":"Sent"):"Received")+" "+displayMsgDateTime(rowdata["conversationitem_date"]+(conversationitemtype>=2 && sendername.length > 0?" ("+sendername+")":""))}</div>
				</div>
			</div>
		}
		return <></>
	}

	function customSearchField(field, idx, rowdata, styles)
	{
		if (field.dbfield === "conversation_msgin" || field.dbfield === "conversation_msgout") {
			if (null !== rowdata[field.dbfield]) {
				const msgdate = field.dbfield === "conversation_msgin"?(null !== rowdata["conversation_datein"]?"\n\n(Received "+displayMsgDateTime(rowdata["conversation_datein"])+")":""):(null !== rowdata["conversation_dateout"]?"\n\n(Sent "+displayMsgDateTime(rowdata["conversation_dateout"])+")":"");
				var newmsg = false; //field.dbfield === "conversation_msgin" && rowdata["conversation_unread"];
				if (rowdata["conversation_datein"] !== null && field.dbfield === "conversation_msgin") {
					if (rowdata["conversation_datein"] >= defaultstartdate) {
						// Within default search range
						newmsg = true;
						if (rowdata["conversation_dateout"] !== null) {
							if (rowdata["conversation_dateout"] > rowdata["conversation_datein"]) {
								newmsg = false;
							}
						}
					} else {
						return <span>{msgdate}</span>
					}
				}
				const tmpstr = rowdata[field.dbfield].substring(0,80)+(rowdata[field.dbfield].length>30?"...":"")+msgdate;
				return <div style={newmsg?{textAlign:"left", fontWeight:"bold"}:{textAlign:"left"}} dangerouslySetInnerHTML={{__html: tmpstr.replace(/\n/g,"<br/>")}} />
			}
		}
		return <></>
	}

	function displayMsgDateTime(datestr)
	{
		if (datestr.indexOf(todaydate) === 0) {
			return formatTools.datetimestr(datestr).substring(11);
		}
		return formatTools.datetimestr(datestr.substring(0,10))
	}

	return <Layout location={location}
			newUserHandler={(newtoken, newuserinfo)=>{setToken(newtoken); setUserinfo(newuserinfo)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowadd={allowadd}
				allowdelete={false}
				entity={ENTITYID}
				mobilerowtitlefield={["conversation_date","conversation_refno"]}
				searchFields={searchFields}
				newformFields={[newformFields]}
				editFields={[formFields]}

				customSearchField={customSearchField}
				customEditFieldInfo={customEditFieldInfo}
				customFieldFragment={customFieldFragment}
				customSetFormData={customSetFormData}
				customSubmit={customSubmit}
				customValidateValues={formValidateValues}

				userSearchParam={{"orderby":"conversation_date desc"}}

				userSearchFilter={allowadd?[]:[
					{"field":"conversation_refno", "operation":" like ", "value": "+63%"},
					{"field":"conversation_refno", "operation":" not like ", "value": "+63923456789%"}
				]}
				token={token} />
		</Layout>

}


export default WebappsConversationpageComponent;
